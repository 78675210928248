import {AlacakKalemiTuru} from "@/enum/AlacakKalemiTuru";
import {FaizEntity} from "@/entity/FaizEntity";
import {HesapSekli} from "@/enum/HesapSekli";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import { TakipEntity } from '../TakipEntity';


export default class AlacakKalemiEntity {
    id: number | null = null;
    takip: TakipEntity | null = null;
    alacakKalemiTuru:AlacakKalemiTuru | null = null;
    tutar: number | null = null;
    paraBirimi: ParaBirimiEntity | null = null;
    vadeTarihi: Date | null = null;
    takipOncesiFaiz: FaizEntity | null = null;
    takibeEklenecekTutar: number| null = null;
    takipOncesiHesapSekli: HesapSekli=HesapSekli.aylik;
    takipSonrasiFaiz: FaizEntity | null = null;
    takipSonrasiHesapSekli: HesapSekli=HesapSekli.aylik;
    
}


