import {NoterEntity} from "@/entity/NoterEntity";
import {IlamsizAlacakKalemiEntity} from "@/entity/AlacakBelgeler/IlamsizAlacakKalemiEntity";
import {SozlesmeTuru} from "@/enum/SozlesmeTuru";
import {ProtestoEntity} from "@/entity/AlacakBelgeler/ProtestoEntity";
import {IhtarEntity} from "@/entity/AlacakBelgeler/IhtarEntity";
import {IlamsizBelgeTipi} from "@/enum/IlamsizBelgeTipi";
import {AdresEntity} from "@/entity/AdresEntity";
import { KiraEntity } from './KiraEntity';

export class KiraFarkiAlacagiEntity extends IlamsizAlacakKalemiEntity {
    sozlesmeTuru: SozlesmeTuru | null = SozlesmeTuru.noter;
    sozlesmeBaslangicTarihi: Date | null = null;
    sozlesmeBitisTarihi: Date | null = null;
    noter: NoterEntity | null = null;
    adres: AdresEntity = new AdresEntity();
    kiralar: Array<KiraEntity> = [];
    protesto: ProtestoEntity | null = null;
    ihtar: IhtarEntity | null = null;

    constructor() {
        super();
        this.belgeTipi = IlamsizBelgeTipi.kiraFarkiAlacak;
        this.kiralar = [];
        this.ihtar = new IhtarEntity();
        this.protesto = new ProtestoEntity();
    }
}
