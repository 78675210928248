import {IlamsizAlacakKalemiEntity} from "@/entity/AlacakBelgeler/IlamsizAlacakKalemiEntity";
import {ProtestoEntity} from "@/entity/AlacakBelgeler/ProtestoEntity";
import {IlamsizBelgeTipi} from "@/enum/IlamsizBelgeTipi";
import { HesapNumarasiEntity } from '../HesapNumarasiEntity';
import { IhtarEntity } from './IhtarEntity';

export class CekEntity extends IlamsizAlacakKalemiEntity {

    cekSeriNo: number | null = null;
    cekKesideTarihi: Date | null = null;
    cekIbrazTarihi: Date | null = null;
    cekKesideYeri:string|null=null;
    bankaBilgileri:HesapNumarasiEntity|null =null;
    cekOdemeYeri:string|null=null;
    ihtar:IhtarEntity|null=null;
    //faizBaslangicTarihi: Date | null = null;



    constructor() {
        super();
        this.belgeTipi = IlamsizBelgeTipi.cek;
        this.ihtar = new IhtarEntity();
    }
}
