import {IlamsizAlacakKalemiEntity} from "@/entity/AlacakBelgeler/IlamsizAlacakKalemiEntity";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import {IlamsizBelgeTipi} from "@/enum/IlamsizBelgeTipi";
import {IhtarEntity} from "@/entity/AlacakBelgeler/IhtarEntity";

export class TuketiciHakemHeyetiKarariEntity extends IlamsizAlacakKalemiEntity {
    sozlesmeTarihi: Date | null = null;
    faizBaslangicTarihi: Date | null = null;
    paraBirimi: ParaBirimiEntity | null = null;
    kararNo: number | null = null;
    kararTarihi: Date | null = null;
    ihtar:IhtarEntity | null = null;

    constructor() {
        super();
        this.belgeTipi = IlamsizBelgeTipi.tuketiciHakemHeyetiKarar
    }
}
