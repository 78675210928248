import {enum2select, SelectableListResponse} from "@/helpers/EnumHelper";
import {IlamTipi} from "@/enum/IlamTipi";



export function IlamTipListResponseProvider(depth:number=3): SelectableListResponse {
    return {
        data: enum2select(IlamTipi)
    };
}
