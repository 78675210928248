import {IhtarEntity} from "@/entity/AlacakBelgeler/IhtarEntity";
import {IlamsizAlacakKalemiEntity} from "@/entity/AlacakBelgeler/IlamsizAlacakKalemiEntity";
import {IlamsizBelgeTipi} from "@/enum/IlamsizBelgeTipi";

export class FaturaEntity extends IlamsizAlacakKalemiEntity {
    tanzimTarihi: Date | null = null;
    faizBaslangicTarihi: Date | null = null;
    ihtar: IhtarEntity | null = null;

    constructor() {
        super();
        this.belgeTipi = IlamsizBelgeTipi.fatura;
        this.ihtar = new IhtarEntity();
    }
}
