import {IlamsizAlacakKalemiEntity} from "@/entity/AlacakBelgeler/IlamsizAlacakKalemiEntity";
import {IhtarEntity} from "@/entity/AlacakBelgeler/IhtarEntity";
import {AdresTuru} from "@/enum/AdresTuru";
import {IlEntity} from "@/entity/IlEntity";
import {IlceEntity} from "@/entity/IlceEntity";
import {IlamsizBelgeTipi} from "@/enum/IlamsizBelgeTipi";
import { AdresEntity } from '../AdresEntity';

export class SozlesmeEntity extends IlamsizAlacakKalemiEntity {
    sozlesmeTarihi: Date | null = null;
    faizBaslangicTarihi: Date | null = null;
    sozlesmeIsmi: string | null = null;
    adres: AdresEntity = new AdresEntity();
    pulOlan: number | null = null;
    pulGercek: number | null = null;
    ihtar: IhtarEntity | null = null;

    constructor() {
        super();
        this.belgeTipi = IlamsizBelgeTipi.sozlesme
    }
}
