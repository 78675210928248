import {TakipEntity} from "@/entity/TakipEntity";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {AlacakKalemiTuru} from "@/enum/AlacakKalemiTuru";
import {FaizEntity} from "@/entity/FaizEntity";
import {FaizOranGecmisEntity} from "@/entity/FaizOranGecmisEntity";
import {Helper} from "@/plugins/helper";
import {TahsilatEntity} from "@/entity/TahsilatEntity";

export class AlacakKalemiFaizHesapDetay {
    baslangicTarihi: Date | null=null;
    bitisTarihi: Date | null=null;
    tutar: number |null=null;
    yillikFaiz: number | null =null;
    gecenGun: number | null = null;
    birGundeIsleyen: number | null = null;
    donemlikToplam: number | null = null;
}


export class AlacakKalemiFaizHesap {
    alacakKalemi:AlacakKalemiEntity;
    tahsilatlar: Array<TahsilatEntity> | null = [];
    detaylar: Array<AlacakKalemiFaizHesapDetay> = [];
    faizToplam:number=0;
    constructor(alacakKalemi: AlacakKalemiEntity) {
        this.alacakKalemi = alacakKalemi;
    }
}

export class FaizHesaplayici {
    takip: TakipEntity;
    faiz: FaizEntity;

    hesaplar: Array<AlacakKalemiFaizHesap> = [];

    toplam!: number;

    constructor(takip: TakipEntity, faiz: FaizEntity) {
        this.takip = takip;
        this.faiz = faiz;
    }

    public hesapla() {
        console.log("FaizHesaplayici::hesapla");

        console.log(this.takip.alacakKalemleri);

        this.takip.alacakKalemleri?.forEach((alacakKalemi: AlacakKalemiEntity) => {
            console.log("Alacak Kalemi", alacakKalemi);
            if (alacakKalemi.alacakKalemiTuru == AlacakKalemiTuru.ilamsiz) {
                this.hesaplar.push( this.yillik360Hesabi(alacakKalemi) );
            }

        })
    }


    protected yillik360Hesabi(alacakKalemi:AlacakKalemiEntity): AlacakKalemiFaizHesap {
        let hesap = new AlacakKalemiFaizHesap(alacakKalemi);
        var alacakToplam = 0;
        const helper = new Helper();
        console.log("yillik360Hesabi",hesap)
        this.faiz.faizGecmis?.forEach((faizDonem: FaizOranGecmisEntity) => {
           // console.log(" - - - - - - - - - - ", i);
            // console.log("faizGecmisForEach::faizDonem", faizDonem);
            // console.log("faizDonemBasTarihIsDate",faizDonem.baslangicTarihi instanceof Date);
            // console.log("vadeTarihiIsDate",hesap.alacakKalemi.vadeTarihi instanceof Date);
            // console.log("faizDonemBisitTarihiIsDate",faizDonem.bitisTarihi instanceof Date)
            // console.log("faizDonemVadeTarihComparisonTo FaizDonemBasTarih",faizDonem.baslangicTarihi < hesap.alacakKalemi.vadeTarihi);
            // console.log("faizDonemVadeTarihComparisonTo FaizDonemBitisTarih",faizDonem.baslangicTarihi < hesap.alacakKalemi.vadeTarihi);

            console.log("faizDonem.baslangicTarihi",faizDonem.baslangicTarihi);
            console.log("faizDonem.bitisTarihi",faizDonem.bitisTarihi);
            console.log("hesap.alacakKalemi.vadeTarihi",hesap.alacakKalemi.vadeTarihi);
            if (
                faizDonem.baslangicTarihi instanceof Date &&
                hesap.alacakKalemi.vadeTarihi instanceof Date &&
                faizDonem.bitisTarihi instanceof Date &&
                this.takip.takipTalebi?.takipTarihi  instanceof Date &&
                (
                    (
                        faizDonem.baslangicTarihi < hesap.alacakKalemi.vadeTarihi &&
                        faizDonem.bitisTarihi > hesap.alacakKalemi.vadeTarihi
                    )
                        ||
                    (
                        faizDonem.baslangicTarihi < this.takip.takipTalebi?.takipTarihi &&
                        faizDonem.bitisTarihi > this.takip.takipTalebi?.takipTarihi
                    )
                        ||
                    (
                        faizDonem.baslangicTarihi > hesap.alacakKalemi.vadeTarihi  &&
                        faizDonem.bitisTarihi < this.takip.takipTalebi?.takipTarihi
                    )

                )

            ) {
                console.log("First IF")
                let hesapDetay = new AlacakKalemiFaizHesapDetay();
                if (hesap.alacakKalemi.tutar!=null && faizDonem.oran != null && hesapDetay.yillikFaiz != null) {
                hesapDetay.yillikFaiz = hesap.alacakKalemi.tutar * faizDonem.oran/100;
                hesapDetay.birGundeIsleyen = hesapDetay.yillikFaiz / 360;
                }
                if (hesap.alacakKalemi.vadeTarihi > faizDonem.baslangicTarihi) {
                    hesapDetay.baslangicTarihi = hesap.alacakKalemi.vadeTarihi;
                } else {
                    hesapDetay.baslangicTarihi = faizDonem.baslangicTarihi;
                }


                if (this.takip.takipTalebi?.takipTarihi > faizDonem.bitisTarihi) {
                    hesapDetay.bitisTarihi = faizDonem.bitisTarihi;
                }else{
                    hesapDetay.bitisTarihi = this.takip.takipTalebi?.takipTarihi;
                }

                hesapDetay.gecenGun = helper.tarihFarkiGunSayisi( hesapDetay.baslangicTarihi, hesapDetay.bitisTarihi);
                if (hesapDetay.birGundeIsleyen!=null)
                    hesapDetay.donemlikToplam = hesapDetay.gecenGun * hesapDetay.birGundeIsleyen;

                hesap.detaylar.push(hesapDetay);
                if (hesapDetay.donemlikToplam!=null)
                hesap.faizToplam += hesapDetay.donemlikToplam;
            }else{
                console.log("First ELSE");
            }
        })
        return hesap;
    }

    protected BugunuGetir(): Date {

        var Today = new Date(Date.now()).toLocaleString();
        return new Date();
    }


}
