












































import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import Cek from "@/components/takip/Belgeler/Cek.vue";
import Senet from "@/components/takip/Belgeler/Senet.vue";
import Police from "@/components/takip/Belgeler/Police.vue";
import Fatura from "@/components/takip/Belgeler/Fatura.vue";
import KiraKontrati from "@/components/takip/Belgeler/KiraKontrati.vue";
import Sozlesme from "@/components/takip/Belgeler/Sozlesme.vue";
import CezaiSart from "@/components/takip/Belgeler/CezaiSart.vue";
import TuketiciHakemHeyetiKarari from "@/components/takip/Belgeler/TuketiciHakemHeyetiKarari.vue";
import Aidat from "@/components/takip/Belgeler/Aidat.vue";
import Diger from "@/components/takip/Belgeler/Diger.vue";
import CariHesapAlacak from "@/components/takip/Belgeler/CariHesapAlacak.vue";
import {IlamsizBelgeTipi} from "@/enum/IlamsizBelgeTipi";
import IptalButton from "@/components/inputs/IptalButton.vue";
import KaydetButton from "@/components/inputs/KaydetButton.vue";
import IlamForm from "@/components/takip/Belgeler/IlamForm.vue";
import {IlamTuru} from "@/enum/IlamTuru";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import BelgeTuruPicker from "@/components/pickers/BelgeTuruPicker.vue";
import {CekEntity} from "@/entity/AlacakBelgeler/CekEntity";
import {KiraFarkiAlacagiEntity} from "@/entity/AlacakBelgeler/KiraFarkiAlacagiEntity";
import {TuketiciHakemHeyetiKarariEntity} from "@/entity/AlacakBelgeler/TuketiciHakemHeyetiKarariEntity";
import {SozlesmeEntity} from "@/entity/AlacakBelgeler/SozlesmeEntity";
import {SenetEntity} from "@/entity/AlacakBelgeler/SenetEntity";
import {PoliceEntity} from "@/entity/AlacakBelgeler/PoliceEntity";
import {DigerEntity} from "@/entity/AlacakBelgeler/DigerEntity";
import {KiraKontratiEntity} from "@/entity/AlacakBelgeler/KiraKontratiEntity";
import {FaturaEntity} from "@/entity/AlacakBelgeler/FaturaEntity";
import {CezaiSartEntity} from "@/entity/AlacakBelgeler/CezaiSartEntity";
import {CariHesapAlacakEntity} from "@/entity/AlacakBelgeler/CariHesapAlacakEntity";


@Component({
    components: {
        BelgeTuruPicker,
        IlamForm,
        KaydetButton,
        IptalButton,
        CariHesapAlacak,
        Aidat, TuketiciHakemHeyetiKarari, CezaiSart, Sozlesme, KiraKontrati, Fatura, Police, Senet, Cek,Diger
    }
})
export default class IlamsizAlacakKalemiForm extends Mixins(ObjectInputMixin) {

    IlamsizBelgeTipi = IlamsizBelgeTipi;
    IlamTuru = IlamTuru;

    belgeTipi: any = null;

    @Watch('belgeTipi')
    onBelgeTipiChange() {
        switch (this.belgeTipi) {
            case IlamsizBelgeTipi.cek:
                this.$emit('input', new CekEntity());
                break;
            case IlamsizBelgeTipi.cariHesapAlacak:
                this.$emit('input', new CariHesapAlacakEntity());
                break;
            case IlamsizBelgeTipi.cezaiSart:
                this.$emit('input', new CezaiSartEntity());
                break;
            case IlamsizBelgeTipi.fatura:
                this.$emit('input', new FaturaEntity());
                break;
            case IlamsizBelgeTipi.kiraKontrat:
                this.$emit('input', new KiraKontratiEntity());
                break;
            case IlamsizBelgeTipi.kiraFarkiAlacak:
                this.$emit('input', new KiraFarkiAlacagiEntity());
                break;
            case IlamsizBelgeTipi.police:
                this.$emit('input', new PoliceEntity());
                break;
            case IlamsizBelgeTipi.senet:
                this.$emit('input', new SenetEntity());
                break;
            case IlamsizBelgeTipi.sozlesme:
                this.$emit('input', new SozlesmeEntity());
                break;
            case IlamsizBelgeTipi.tuketiciHakemHeyetiKarar:
                this.$emit('input', new TuketiciHakemHeyetiKarariEntity());
                break;
            case IlamsizBelgeTipi.diger:
                this.$emit('input', new DigerEntity());
                break;
        }
    }

    mounted(){
        console.log("ilamsız local value",this.localValue);
    }


}
