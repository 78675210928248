var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',[_c('v-row',{attrs:{"justify":"left"}},[_c('v-col',{attrs:{"md":"8"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-toolbar',{attrs:{"dense":"","dark":"","color":"cyan"}},[_c('v-toolbar-title',[_vm._v(" Takip Bilgileri ")])],1),_c('v-list-item-subtitle',[_c('takip-talebi-form',{model:{value:(_vm.takip.takipTalebi),callback:function ($$v) {_vm.$set(_vm.takip, "takipTalebi", $$v)},expression:"takip.takipTalebi"}})],1)],1)]}}])}),_c('br'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('item-array-manager',{attrs:{"type":"table","editable":"","deletable":"","color":"#039BE5","title":"Alacaklı Bilgileri","headers":_vm.alacaklilarHeaders},scopedSlots:_vm._u([{key:"form",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('alacakli-form',{on:{"input":onInput},model:{value:(formData),callback:function ($$v) {formData=$$v},expression:"formData"}})]}},{key:"toolbar-ext",fn:function(){return [_c('form-dialog-button',{attrs:{"title":"Takip İçin Alacaklı Seçimi","dark":"","color":"#039BE5","icon":"mdi-plus"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('takip-alacakli-sec',{on:{"succes":_vm.VekaletSucces},model:{value:(_vm.takip),callback:function ($$v) {_vm.takip=$$v},expression:"takip"}})]}}],null,true)})]},proxy:true}],null,true),model:{value:(_vm.takip.alacaklilar),callback:function ($$v) {_vm.$set(_vm.takip, "alacaklilar", $$v)},expression:"takip.alacaklilar"}})],1)]}}])}),_c('br'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[_c('item-array-manager',{attrs:{"type":"table","editable":"","deletable":"","crud":"","color":"#F4511E","title":"Borçlu Bilgileri","headers":_vm.borclularHeaders,"btncolor":"#F4511E"},scopedSlots:_vm._u([{key:"form",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('borclu-form',{on:{"input":onInput},model:{value:(formData),callback:function ($$v) {formData=$$v},expression:"formData"}})]}}],null,true),model:{value:(_vm.takip.borclular),callback:function ($$v) {_vm.$set(_vm.takip, "borclular", $$v)},expression:"takip.borclular"}})],1)]}}])}),_c('br'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[_c('item-array-manager',{attrs:{"type":"table","crud":"","color":"navy","btncolor":"navy","title":"Tereke Bilgisi","headers":_vm.borclularHeaders},scopedSlots:_vm._u([{key:"form",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('borclu-form',{on:{"input":onInput},model:{value:(formData),callback:function ($$v) {formData=$$v},expression:"formData"}})]}}],null,true),model:{value:(_vm.takip.terekeler),callback:function ($$v) {_vm.$set(_vm.takip, "terekeler", $$v)},expression:"takip.terekeler"}})],1)]}}])}),_c('br'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[_c('item-array-manager',{attrs:{"type":"table","detail":"","crud":_vm.alacakKalemiEklenebilirMi,"color":"#43A047","btncolor":"#43A047","title":"Alacak Bilgileri","headers":_vm.alacakKalemleriHeaders},scopedSlots:_vm._u([{key:"detayForm",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('alacak-kalemi-detay-form',{staticClass:"mt-5",attrs:{"type":"takipTalebi","items":formData},on:{"input":onInput}})]}},{key:"form",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('alacak-kalemi-form',{attrs:{"takip-talebi":_vm.takip.takipTalebi,"takip-alacak-kalem-turu":_vm.getTakipAlacakKalemTuru},on:{"input":onInput},model:{value:(formData),callback:function ($$v) {formData=$$v},expression:"formData"}})]}}],null,true),model:{value:(_vm.takip.alacakKalemleri),callback:function ($$v) {_vm.$set(_vm.takip, "alacakKalemleri", $$v)},expression:"takip.alacakKalemleri"}})],1)]}}])}),_c('br'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[(_vm.isTasinirRehni)?_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","color":"#6D4C41","dark":""}},[_c('v-toolbar-title',[_vm._v("Taşınır Rehni Veya İpotek")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"#6D4C41"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)],1),_c('br')],1):_vm._e()],1)]}}])}),(_vm.isTasinirRehni)?_c('br'):_vm._e(),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[(_vm.isIlamli)?_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","dark":"","color":"#FB8C00"}},[_c('v-toolbar-title',[_vm._v("İlam Bilgileri")]),_c('v-spacer'),_c('form-dialog-button',{attrs:{"title":"İlam Bilgileri","dark":"","icon":"mdi-pencil","color":"#FB8C00"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('ilam-bilgileri-form',{on:{"input":onInput},model:{value:(formData),callback:function ($$v) {formData=$$v},expression:"formData"}})]}}],null,true),model:{value:(_vm.takip.ilamBilgisi),callback:function ($$v) {_vm.$set(_vm.takip, "ilamBilgisi", $$v)},expression:"takip.ilamBilgisi"}})],1),_c('v-card-text',[(_vm.takip.ilamBilgisi != null)?_c('v-list-item-subtitle',[_c('p',[_vm._v("İlam Tipi:"+_vm._s(_vm.takip.ilamBilgisi.ilamTipi)+" "),_c('v-spacer'),_vm._v(" İlam Özeti: "+_vm._s(_vm.takip.ilamBilgisi.ilamOzeti)+" ")],1)]):_vm._e()],1),_c('br')],1):_vm._e()],1)]}}])}),(_vm.isIlamli)?_c('br'):_vm._e(),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[(_vm.isKiraKontrati)?_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","color":"#546E7A","dark":""}},[_c('v-toolbar-title',[_vm._v("Kira Kontratı")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"#546E7A"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)],1),_c('br')],1):_vm._e()],1)]}}])}),(_vm.isKiraKontrati)?_c('br'):_vm._e(),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[(_vm.isBorcunSebebi)?_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","color":"#00897B","dark":""}},[_c('v-toolbar-title',[_vm._v("Borcun Sebebi")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"#00897B"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)],1),_c('br')],1):_vm._e()],1)]}}])}),_c('br'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","color":"#757575","dark":""}},[_c('v-toolbar-title',[_vm._v("Takip Yolu")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"#757575"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)],1),_c('br')],1)],1)]}}])})],1),_c('v-col',{attrs:{"md":"8","lg":"4"}},[(false)?_c('yeni-takip-vekalet-listesi',{attrs:{"items":_vm.takip.vekaletler}}):_vm._e(),_c('hesap-dokum',{staticClass:"mt-0",attrs:{"type":"takipTalebi","toolbar":true},model:{value:(_vm.takip.alacakKalemleri),callback:function ($$v) {_vm.$set(_vm.takip, "alacakKalemleri", $$v)},expression:"takip.alacakKalemleri"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }