import {HesapSekli} from "@/enum/HesapSekli";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import {IlamsizBelgeTipi} from "@/enum/IlamsizBelgeTipi";
import {AlacakKalemiTuru} from "@/enum/AlacakKalemiTuru";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import { ProtestoIhtarEntity } from './ProtestoIhtarEntity';

export abstract class IlamsizAlacakKalemiEntity extends AlacakKalemiEntity {
    belgeTarihi: Date | null = null;
    aciklama: string | null = null;
    belgeTipi!: IlamsizBelgeTipi;
    alacakKalemiTuru:AlacakKalemiTuru = AlacakKalemiTuru.ilamsiz;
    islemBaslasinMi:boolean|null=null;
}
