




















































































































import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import MuvekkilList from "@/components/vekalet/MuvekkilList.vue";
import {TakipEntity} from "@/entity/TakipEntity";
import {MuvekkilListResponseProvider} from "@/services/MuvekkilService";
import {MuvekkilEntity} from "@/entity/MuvekkilEntity";
import {AlacakliEntity} from "@/entity/AlacakliEntity";
import {VekaletEntity} from "@/entity/VekaletEntity";
import {VekaletListResponse, VekaletListResponseProvider} from "@/services/VekaletService";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";


@Component({
    components: {MuvekkilList}
})

export default class TakipAlacakliSec extends Vue {
    muvekkiller: Array<MuvekkilEntity> | null = null;
    secilenMuvekkil: MuvekkilEntity | null = null;
    muvekkillinVekaletleri: Array<VekaletEntity> | null = null;
    vekaletinMuvekkilleri: Array<MuvekkilEntity> | null = null;
    alacakliOlacakMuvekkiller: Array<MuvekkilEntity> | null = null;
    e1 = 1;
    n= 0;
  //  n!: number;
    search = '';
    @Prop({})
    value!: TakipEntity;

    @Watch('e1')
    steps(val: any) {
        if (this.e1 > val) {
            this.e1 = val
        }
    }

    @Watch('alacakliOlacakMuvekkiller')
    onvaluechange(){
        this.value.alacaklilar = [];
        this.alacakliOlacakMuvekkiller?.forEach((muvekkil: MuvekkilEntity)=>{
            let yeniAlacak = new AlacakliEntity();
            yeniAlacak.muvekkil = muvekkil;
            yeniAlacak.harcMuaf = false;
            yeniAlacak.takip = this.value;
            this.value.alacaklilar?.push(yeniAlacak);
        })
    }

    created() {
       this.muvekkiller = MuvekkilListResponseProvider(4).data;
       this.muvekkillinVekaletleri = VekaletListResponseProvider(4,3).data;
       this.vekaletinMuvekkilleri = MuvekkilListResponseProvider(4).data;
        window.setTimeout(() => {
            console.log("takip", this.value)
        }, 220);
    }
    MuvekkilSec(item:MuvekkilEntity)
    {
        this.e1 =2;
        this.muvekkillinVekaletleri = item.vekalet;
        this.secilenMuvekkil = item;
        console.log("secilen muvekkil: ",this.secilenMuvekkil)
    }
    VekaletSec(item:VekaletEntity)
    {
        this.vekaletinMuvekkilleri = item.muvekkiller;
        console.log("secilen vekaletin müvekkilleri: ",this.vekaletinMuvekkilleri)
        this.e1 =3;
        console.log("secilen vekalet: ",item)
        this.value.vekaletler?.push(item);
        if(this.vekaletinMuvekkilleri?.length == 1)
        {
            this.alacakliOlacakMuvekkiller = this.vekaletinMuvekkilleri;
            this.$emit('succes',item);
            this.e1=1;
        }

    }
    headers = [
        {
            text: "Adı",
            align: "start",
            sortable: false,
            value: "kisiKurum.ad"
        },

        {text: "TC Kimlik No / Vergi Kimlik No", value: "kisiKurum.tc"},
        {text: "İşlemler", value: "actions", sortable: false}

    ];
    VekaletHeaders = [
        {
            text: "Vekalet",
            align: "start",
            sortable: false,
            value: "ad"
        },
        {text: "Noter", value: "noterIslem.noter.ad"},
        {text: "Vekaletname Tarihi", value: "noterIslem.tarih"},
        {text: "İşlemler", value: "actions", sortable: false}
    ];
}

