import {ProtestoEntity} from "@/entity/AlacakBelgeler/ProtestoEntity";
import {IhtarEntity} from "@/entity/AlacakBelgeler/IhtarEntity";
import {IlamsizAlacakKalemiEntity} from "@/entity/AlacakBelgeler/IlamsizAlacakKalemiEntity";
import {IlEntity} from "@/entity/IlEntity";
import {IlamsizBelgeTipi} from "@/enum/IlamsizBelgeTipi";

export class PoliceEntity extends IlamsizAlacakKalemiEntity {
    id: number | null = null;
    kesideTarihi: Date | null = null;
    kesideci: string | null = null;
    kesideYeri: IlEntity | null = null;
    odemeYeri: IlEntity | null = null;
    faizBaslangicTarihi: Date | null = null;
    odeyenKisi: string | null = null;
    pulOlan: number | null = null;
    pulGercek: number | null = null;
    lehtar: string | null = null;
    protesto: ProtestoEntity | null = null;
    ihtar: IhtarEntity | null = null;

    constructor() {
        super();
        this.belgeTipi = IlamsizBelgeTipi.police;
        this.ihtar = new IhtarEntity();
        this.protesto = new ProtestoEntity();
    }
}
