
























































































































































































































import {Component, Vue} from "vue-property-decorator";
import TakipTalebiList from "@/components/takip/list/TakipTalebiList.vue";
import BorclularList from "@/components/takip/list/BorclularList.vue";
import AlacaklilarList from "@/components/takip/list/AlacaklilarList.vue";
import TerekeList from "@/components/takip/list/TerekeList.vue";
import IlamBilgileriList from "@/components/takip/list/IlamBilgileriList.vue";
import BorcunSebebiList from "@/components/takip/list/BorcunSebebiList.vue";
import TakipYoluList from "@/components/takip/list/TakipYoluList.vue";
import KiraBilgileriList from "@/components/takip/list/KiraBilgileriList.vue";
import TakipTalebiForm from "@/components/takip/form/TakipTalebiForm.vue";
import AlacakKalemiDetayForm from "@/components/takip/form/AlacakKalemiDetayForm.vue";
import MuvekkilList from "@/components/vekalet/MuvekkilList.vue";
import KisiKurumForm from "@/components/kisi/kisiKurumForm.vue";
import AlacakKalemleriForm from "@/components/takip/form/AlacakKalemleriForm.vue";
import AlacakKalemleriList from "@/components/takip/list/AlacakKalemleriList.vue";
import IlamliAlacakKalemiForm from "@/components/takip/form/IlamliAlacakKalemiForm.vue";
import IlamBilgileriForm from "@/components/takip/form/IlamBilgileriForm.vue";
import BorcluForm from "@/components/takip/form/BorcluForm.vue";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import {AlacakKalemiTuru} from "@/enum/AlacakKalemiTuru";
import AlacakKalemiForm from "@/components/takip/form/AlacakKalemiForm.vue";
import {TakipEntity} from "@/entity/TakipEntity";
import AlacakliForm from "@/components/takip/form/AlacakliForm.vue";
import {AlacakliEntity} from "@/entity/AlacakliEntity";
import {ItemArrayManagerTableHeader} from '@/interface/TableHeader';
import {IlamsizBelgeTipiLabel} from "@/enum/IlamsizBelgeTipi";
import {FaizHesaplayici} from "@/classes/faizHesaplayici";
import {FaizListResponseProvider} from "@/services/FaizService";
import {TakipListResponseProvider} from "@/services/TakipService";
import TakipAlacakliSec from "@/components/takip/TakipAlacakliSec.vue";
import YeniTakipVekaletListesi from "@/components/takip/YeniTakipVekaletListesi.vue";
import {VekaletEntity} from "@/entity/VekaletEntity";
import {IlamsizAlacakKalemiEntity} from "@/entity/AlacakBelgeler/IlamsizAlacakKalemiEntity";
import HesapDokum from "@/components/anakart/HesapDokum.vue";
import {IlamliAlacakKalemiEntity} from '@/entity/AlacakBelgeler/IlamliAlacakKalemiEntity';
import {IlamTuru, IlamTuruLabel} from "@/enum/IlamTuru";
import {BorcluEntity} from "@/entity/BorcluEntity";


@Component({
    components: {
        HesapDokum,
        YeniTakipVekaletListesi,
        TakipAlacakliSec,
        AlacakliForm,
        AlacakKalemiForm,
        FormDialogButton,
        ItemArrayManager,
        BorcluForm,
        IlamBilgileriForm,
        IlamliAlacakKalemiForm,
        AlacakKalemleriList,
        AlacakKalemleriForm,
        KisiKurumForm,
        MuvekkilList,
        TakipTalebiForm,
        KiraBilgileriList,
        TakipYoluList,
        BorcunSebebiList,
        IlamBilgileriList, TerekeList, AlacaklilarList, BorclularList, TakipTalebiList, AlacakKalemiDetayForm
    }
})
export default class TakipDetayi extends Vue {
    //takipTalebiObject: TakipTalebiEntity = new TakipTalebiEntity({});
    takip = new TakipEntity();// TakipListResponseProvider().data[0];
    seciliVekaletler!: Array<VekaletEntity>;
    transparent = 'rgba(255, 255, 255, 0)';

    created() {
        this.takip = TakipListResponseProvider(9).data[0];
        console.log("ilk takip:", this.takip);
    }

    faizHesabi = new FaizHesaplayici(this.takip, FaizListResponseProvider().data[1]);

    faizHesapla() {
        this.faizHesabi = new FaizHesaplayici(this.takip, FaizListResponseProvider().data[1]);
        this.faizHesabi.hesapla();
    }

    get getTakipAlacakKalemTuru(): string {
        if (this.takip.takipTalebi?.takipTipi?.deger == '53' || this.takip.takipTalebi?.takipTipi?.deger == '54' || this.takip.takipTalebi?.takipTipi?.deger == "55" || this.takip.takipTalebi?.takipTipi?.deger == "151" || this.takip.takipTalebi?.takipTipi?.deger == "201") {
            return AlacakKalemiTuru.ilamli;
        }
        return AlacakKalemiTuru.ilamsiz;

    }

    get alacakKalemleriHeaders(): Array<object> {
        if (this.getTakipAlacakKalemTuru == AlacakKalemiTuru.ilamli) {
            return this.ilamliAlacakKalemleriHeaders;
        }
        if (this.getTakipAlacakKalemTuru == AlacakKalemiTuru.ilamsiz) {
            return this.ilamsizAlacakKalemleriHeaders;
        }
        return [];
    }

    VekaletSucces(item: VekaletEntity) {
        console.log("aldım:", item);
        if (this.takip.vekaletler!.indexOf(item) === -1) {
            this.takip.vekaletler!.push(item);
        }

    }

    ilamsizAlacakKalemleriHeaders: Array<ItemArrayManagerTableHeader> = [
        {
            text: "Belge Tipi",
            value: "belgeTipi",
            renderer: (row: IlamsizAlacakKalemiEntity) => IlamsizBelgeTipiLabel[row.belgeTipi]
        },
        {
            text: "Tutar",
            value: "tutar",
            renderer: (row: any) => this.$helper.tutarStr(row.tutar)
        },
        {
            text: "Vade Tarihi",
            value: "vadeTarihi",
            renderer: (row: any) => this.$helper.tarihStr(row.vadeTarihi)
        },
        {
            text: "Takibe Esas Tutar",
            value: "takibeEklenecekTutar",
            renderer: (row: any) => this.$helper.tutarStr(row.takibeEklenecekTutar)
        }
    ];

    ilamliAlacakKalemleriHeaders: Array<ItemArrayManagerTableHeader> = [
        {
            text: "Alacak Kalem Türü",
            value: "ilamTuru",
            renderer: (row: IlamliAlacakKalemiEntity) => IlamTuruLabel[row.ilamTuru ? row.ilamTuru : IlamTuru.genel]
        }
    ];
    borclularHeaders = [
        {
            text: "Adı Soyadı/Unvanı",
            align: "start",
            sortable: false,
            value: "kisiKurum",
            renderer: (row: BorcluEntity) => this.$helper.kisiKurumAd(row.kisiKurum)
        },
        {
            text: "TC Kimlik No/ Vergi Kimlik No",
            value: "kisiKurum.tc",
            renderer: (row: BorcluEntity) => this.$helper.tcknOrVergiNo(row.kisiKurum)
        },
    ];

    alacaklilarHeaders: Array<ItemArrayManagerTableHeader> = [
        {
            text: "Adı Soyadı / Unvanı",
            value: "kisiKurum",
            renderer: (row: AlacakliEntity) => this.$helper.kisiKurumAd(row.muvekkil?.kisiKurum)
        },
        {
            text: "TC Kimlik No / Vergi Kimlik No",
            value: "kisiKurum",
            renderer: (row: AlacakliEntity) => this.$helper.tcknOrVergiNo(row.muvekkil?.kisiKurum)
        }
    ];

    get alacakKalemiEklenebilirMi(): boolean {
        if (this.takip.takipTalebi?.takipTipi?.deger == null) {
            return false;
        }
        //if (this.isIlamli) {
        //  return this.takip.takipTalebi.ilamTuru != null
        //}
        return true;
    }

    get isIlamli(): boolean {
        let ilamli = this.takip.takipTalebi?.takipTipi?.deger;
        return ["53", "54", "55", "151", "201"].includes(ilamli ? ilamli : "");
    }

    get isTasinirRehni(): boolean {
        let tasinirRehni = this.takip.takipTalebi?.takipTipi?.deger;
        return ["151", "152", "201"].includes(tasinirRehni ? tasinirRehni : "");
    }

    get isKiraKontrati(): boolean {
        let kiraKontrati = this.takip.takipTalebi?.takipTipi?.deger;
        return ["49", "51", "56"].includes(kiraKontrati ? kiraKontrati : "");
    }

    get isBorcunSebebi(): boolean {
        return !this.isIlamli;
    }
}
