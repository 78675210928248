import {ProtestoEntity} from "@/entity/AlacakBelgeler/ProtestoEntity";
import {IhtarEntity} from "@/entity/AlacakBelgeler/IhtarEntity";
import {IlamsizAlacakKalemiEntity} from "@/entity/AlacakBelgeler/IlamsizAlacakKalemiEntity";
import {IlamsizBelgeTipi} from "@/enum/IlamsizBelgeTipi";

export class SenetEntity extends IlamsizAlacakKalemiEntity {
    tanzimTarihi: Date | null = null;
    faizBaslangicTarihi: Date | null = null;
    kefilVarMi: boolean | null = false;
    protesto: ProtestoEntity | null = null;
    ihtar: IhtarEntity | null = null;
    senetTanzimYeri:string|null=null;
    olmasiGerekenPulDegeri:number|null=null;
    uzerindekiPulDegeri:number|null=null;

    constructor() {
        super();
        this.belgeTipi = IlamsizBelgeTipi.senet;
        this.protesto = new ProtestoEntity();
        this.ihtar = new IhtarEntity();
    }
}



