import {IlamsizAlacakKalemiEntity} from "@/entity/AlacakBelgeler/IlamsizAlacakKalemiEntity";
import {IhtarEntity} from "@/entity/AlacakBelgeler/IhtarEntity";
import {IlamsizBelgeTipi} from "@/enum/IlamsizBelgeTipi";

export class CariHesapAlacakEntity extends IlamsizAlacakKalemiEntity {
    sozlesmeTarihi: Date | null = null;
    vadeTarihi: Date | null = null;
    faizBaslangicTarihi: Date | null = null;
    sozlesmeIsmi: string | null = null;
    ihtar: IhtarEntity | null = null;

    constructor() {
        super();
        this.belgeTipi = IlamsizBelgeTipi.cariHesapAlacak
        this.ihtar = new IhtarEntity();
    }
}
