import {NoterEntity} from "@/entity/NoterEntity";
import {ProtestoIhtarEnum} from "@/enum/ProtestoIhtarEnum";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import { IlamsizAlacakKalemiEntity } from './IlamsizAlacakKalemiEntity';


export abstract class ProtestoIhtarEntity {
    id:number | null = null;
    tur: ProtestoIhtarEnum | null = null;
    noter: NoterEntity | null = null;
    yevmiyeNo: number | null = null;
    tarih: Date | null = null;
    tutar: number | null = null;
    paraBirimi : ParaBirimiEntity | null = null;
}