

































































import {Component, Prop, Vue} from "vue-property-decorator";
import IptalButton from "@/components/inputs/IptalButton.vue";
import KaydetButton from "@/components/inputs/KaydetButton.vue";
import IlPicker from "@/components/pickers/IlPicker.vue";
import Dates from "@/components/inputs/Dates.vue";
import {IlamBilgisiEntity} from "@/entity/IlamBilgisiEntity";
import {IlamTipListResponseProvider} from "@/services/AlacakBelgeleri/IlamTipiService";
import {IlamTipi} from "@/enum/IlamTipi";


@Component({
    components: {
        Dates,
        IlPicker,
        KaydetButton,
        IptalButton,
    }
})
export default class IlamBilgileriForm extends Vue {
    @Prop({})
    value!: IlamBilgisiEntity;
    ilamTipleri = IlamTipListResponseProvider().data;
    ilamTipi = IlamTipi;

    close() {
        console.log("close event emitted");
        this.$emit("close");
    }

    save() {

    }
}
