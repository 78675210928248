








import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import IlamliAlacakKalemiForm from "@/components/takip/form/IlamliAlacakKalemiForm.vue";
import IlamsizAlacakKalemiForm from "@/components/takip/form/IlamsizAlacakKalemiForm.vue";
import {AlacakKalemiTuru} from "@/enum/AlacakKalemiTuru";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
@Component({
    components: {IlamsizAlacakKalemiForm, IlamliAlacakKalemiForm}
})
export default class AlacakKalemiForm extends Mixins(ObjectInputMixin) {

    @Prop({required: true}) takipAlacakKalemTuru!: AlacakKalemiTuru;
    @Prop() takipTalebi!: TakipTalebiEntity;

    get isIlamli(): boolean {

        console.log("ne geldi kaan",this.takipAlacakKalemTuru);
        return this.takipAlacakKalemTuru == AlacakKalemiTuru.ilamli;

    }

    mounted(){
        console.log("localValue -->",this.localValue);
    }
}
