import {IlamsizAlacakKalemiEntity} from "@/entity/AlacakBelgeler/IlamsizAlacakKalemiEntity";
import {IlamsizBelgeTipi} from "@/enum/IlamsizBelgeTipi";
import { IhtarEntity } from './IhtarEntity';

export class CezaiSartEntity extends IlamsizAlacakKalemiEntity {
    sozlesmeTarihi: Date | null = null;
    faizBaslangicTarihi: Date | null = null;
    ihtar: IhtarEntity | null = null;

    constructor() {
        super();
        this.belgeTipi = IlamsizBelgeTipi.cezaiSart
        this.ihtar = new IhtarEntity();
    }
}
