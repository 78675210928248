import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {MasrafEntity} from "@/entity/MasrafEntity";
import {IlamBilgisiEntity} from "@/entity/IlamBilgisiEntity";
import {BorcluEntity} from "@/entity/BorcluEntity";
import {AlacakliEntity} from "@/entity/AlacakliEntity";
import {IlamliAlacakKalemiEntity} from "@/entity/AlacakBelgeler/IlamliAlacakKalemiEntity";
import {IlamsizAlacakKalemiEntity} from "@/entity/AlacakBelgeler/IlamsizAlacakKalemiEntity";
import {VekaletEntity} from "@/entity/VekaletEntity";
import {HarcKalemiEntity} from "@/entity/HarcKalemiEntity";


export class TakipEntity {
    id: number|null = null;
    takipTalebi: TakipTalebiEntity = new TakipTalebiEntity();
    alacaklilar: Array<AlacakliEntity> = [];
    vekaletler:Array<VekaletEntity> = [];
    borclular: Array<BorcluEntity> = [];
    harclar: HarcKalemiEntity | null = null;
    alacakKalemleri: Array<IlamliAlacakKalemiEntity|IlamsizAlacakKalemiEntity> = [];
    masraflar: Array<MasrafEntity> = [];
    ilamBilgisi: IlamBilgisiEntity|null = null;

    /*
    terekeler!:Array<KisiEntity>;
    ilamlar!:Array<>;
    */

    constructor() {

    }
}
