
import {KisiEntity} from "@/entity/KisiEntity";
import {KurumEntity} from "@/entity/KurumEntity";
import {VekaletEntity} from "@/entity/VekaletEntity";
import {MuvekkilEntity} from "@/entity/MuvekkilEntity";
import { TakipEntity } from './TakipEntity';

export class AlacakliEntity {
    id: number | null = null;
    takip: TakipEntity|null=null
    muvekkil: MuvekkilEntity | null = null;
    harcMuaf: boolean | null = null;

}
