import {NoterEntity} from "@/entity/NoterEntity";
import {IlamsizAlacakKalemiEntity} from "@/entity/AlacakBelgeler/IlamsizAlacakKalemiEntity";
import {SozlesmeTuru} from "@/enum/SozlesmeTuru";
import {AdresTuru} from "@/enum/AdresTuru";
import {IlceEntity} from "@/entity/IlceEntity";
import {KiraEntity} from "@/entity/AlacakBelgeler/KiraEntity";
import {ProtestoEntity} from "@/entity/AlacakBelgeler/ProtestoEntity";
import {IhtarEntity} from "@/entity/AlacakBelgeler/IhtarEntity";
import {IlamsizBelgeTipi} from "@/enum/IlamsizBelgeTipi";
import {AdresEntity} from "@/entity/AdresEntity";

export class KiraKontratiEntity extends IlamsizAlacakKalemiEntity {
    sozlesmeTuru: SozlesmeTuru | null = SozlesmeTuru.noter;
    sozlesmeBaslangicTarihi: Date | null = null;
    sozlesmeBitisTarihi: Date | null = null;
    adres: AdresEntity = new AdresEntity();
    noter: NoterEntity = new NoterEntity();
    kiralar: Array<KiraEntity> = [];
    protesto: ProtestoEntity = new ProtestoEntity();
    ihtar: IhtarEntity = new IhtarEntity();
    hazirlanisTarihi:Date|null=null;

    constructor() {
        super();
        this.belgeTipi = IlamsizBelgeTipi.kiraKontrat
        this.kiralar = [];
        this.ihtar = new IhtarEntity();
        this.protesto = new ProtestoEntity();

    }
}
